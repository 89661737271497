
  import { defineComponent, reactive, computed, PropType, toRefs, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { required, helpers } from "@vuelidate/validators";
  import dayjs from 'dayjs';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { IErrorFields, IChildForm, ISchool, IChild } from '@/types';
  import { clearErrorField, handleSetErrors, validateField, validDate } from '@/core/helper-functions';
  import { addChildFormRules } from '@/views/children/children-validation-rules';

  export default defineComponent({
    name: 'ChildForm',

    components: { AppInput, AppSelect, AppButton },

    props: {
      data: {
        type: Object as PropType<IChildForm>,
        required: true
      },

      stage: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

      schools: {
        type: Array as PropType<ISchool[]>,
        default: () => []
      }
    },

    emits: ['submit', 'remove'],

    setup(props, { emit }) {
      const router = useRouter();
      const { data } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({
        firstName: '',
        lastName: '',
        studentId: '',
        nickname: '',
        school: '',
        grade: '',
        otherSchoolName: '',
        day: '',
        month: '',
        year: ''
      });

      const listOfMonth = ref([
        { name: 'January', value: '01' },
        { name: 'February', value: '02' },
        { name: 'March', value: '03' },
        { name: 'April', value: '04' },
        { name: 'May', value: '05' },
        { name: 'June', value: '06' },
        { name: 'July', value: '07' },
        { name: 'August', value: '08' },
        { name: 'September', value: '09' },
        { name: 'October', value: '10' },
        { name: 'November', value: '11' },
        { name: 'December', value: '12' },
      ]);

      const showOtherSchoolInput = computed<boolean>(() => data.value.school?.name === 'Other');

      // validate otherSchoolName if user select "Other" in school section
      const v$ = useVuelidate({
        ...addChildFormRules,
        otherSchoolName: computed<any>(() => showOtherSchoolInput.value && { required: helpers.withMessage('This field cannot be empty', required) }),
        day: computed<any>(() => (
          {
            ...addChildFormRules.day,
            invalideDate: helpers.withMessage(
              'Please enter real date', () => {
                const date = data.value.month?.value + '/' + data.value.day + '/' + data.value.year;
                return validDate(date, 'MM/D/YYYY') && !dayjs(date).isAfter(dayjs());
              }
            )
          })
        )
      }, data.value);

      const availableGradeList = computed(() => {
        if (showOtherSchoolInput.value) {
          return [
            { value: 'pre-K' },
            { value: 'K' },
            { value: 'Grade 1' },
            { value: 'Grade 2' },
            { value: 'Grade 3' },
            { value: 'Grade 4' },
            { value: 'Grade 5' },
            { value: 'Grade 6' },
            { value: 'Grade 7' },
            { value: 'Grade 8' },
            { value: 'Grade 9' },
            { value: 'Grade 10' },
            { value: 'Grade 11' },
            { value: 'Grade 12' },
          ];
        }
        if (data.value.school.grades) {
          return data.value.school.grades.map((el: string) => {
            return { value: el };
          });
        }
        return [];
      });

      const schoolForSubmit = computed(() => {
        return showOtherSchoolInput.value
          ? { id: null, name: data.value.otherSchoolName }
          : { id: data.value.school.id, name: null };
      });

      const dataForSubmit = computed<Partial<IChild>>(() => {
        const {
          firstName,
          lastName,
          nickname,
          studentId,
          grade,
          day,
          month,
          year
        } = data.value;

        return {
          firstName,
          lastName,
          birthMonthDate: dayjs(year + '-' + (month as any).value + '-' + day).toISOString(),
          nickname: nickname ? nickname : null,
          studentId: studentId ? studentId : null,
          otherSchoolName: schoolForSubmit.value.name,
          grade: grade.value,
          schoolId: schoolForSubmit.value.id
        };
      });

      async function onSubmit() {

        if (await v$.value.$validate()) { emit('submit', dataForSubmit.value); }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      function onSchoolChanged() {
        data.value.grade = {};
      }

      return {
        errorMessages,
        availableGradeList,
        router,
        listOfMonth,
        showOtherSchoolInput,
        v$,

        onSubmit,
        onSchoolChanged,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
