
import { defineComponent, computed, PropType } from 'vue';
import { useRoute } from 'vue-router';

import AppIcon from '@/components/stateless/AppIcon.vue';

import { IBreadCrumb } from '@/types';
import { capitalizeFirstLetter } from '@/core/helper-functions';

export default defineComponent({
  name: 'AppBreadCrumbs',

  components: { AppIcon },

  props: {
    items: {
      type: Array as PropType<IBreadCrumb[]>,
    }
  },

  setup(props) {
    const route = useRoute();
    const breadCrumbs = computed<IBreadCrumb[]>(() => {
      if (props.items?.length) { return props.items; }

      let pathArray = route.path.split("/");
      pathArray.shift();

      let crumbs = pathArray.reduce((breadcrumbArray: any, path: string, idx: number) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1] ? "/" + breadcrumbArray[idx - 1].path + "/" + path : "/" + path,
          text: route.matched[idx].meta.breadCrumb || capitalizeFirstLetter(path),
        });

        return breadcrumbArray;
      }, []);

      return [
        { path: '/', to: '/home', text: 'Home' },
        ...crumbs.filter((el: IBreadCrumb) => el.text !== 'Home')
      ];
    });

    return {
      crumbs: props.items || breadCrumbs
    };
  }
});
