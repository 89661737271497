<template>
  <div class="relative bg-white rounded-5 h-full mb-40">
    <div class="px-14 sm:px-25 py-14 sm:py-25 mb-50 md:mb-0">
      <div class="flex items-center justify-between">
        <p class="text-dark-cl-20 md:text-1xl mb-25">{{ stage === 'create' ? 'Add Child' : 'Update child' }}</p>
        <p
          v-if="stage !== 'create'"
          class="text-error block sm:hidden mb-25"
          @click="$emit('remove', data);"
        >
          Remove Child
        </p>
      </div>

      <form
        class="max-w-350"
        @submit.prevent="onSubmit"
      >
        <AppInput
          v-model="v$.firstName.$model"
          label="First Name"
          name="firstName"
          class="mb-18"
          maxlength="100"
          :error="errorMessages.firstName"
          @focus="clearErrorField('firstName')"
          @blur="validateField('firstName')"
        />

        <AppInput
          v-model="v$.lastName.$model"
          label="Last Name"
          name="lastName"
          class="mb-18"
          maxlength="100"
          :error="errorMessages.lastName"
          @focus="clearErrorField('lastName')"
          @blur="validateField('lastName')"
        />

        <AppInput
          v-model="v$.nickname.$model"
          name="nickname"
          class="mb-18"
          maxlength="100"
          :error="errorMessages.nickname"
          @focus="clearErrorField('nickname')"
          @blur="validateField('nickname')"
        >
          <template #label>
            <p class="text-dark-cl-20">Nickname <span class="text-grey-fp-60">(Optional)</span></p>
          </template>
        </AppInput>

        <AppInput
          v-model="v$.studentId.$model"
          name="studentId"
          class="mb-18"
          :error="errorMessages.studentId"
          @focus="clearErrorField('studentId')"
          @blur="validateField('studentId')"
        >
          <template #label>
            <p class="text-dark-cl-20">Student ID <span class="text-grey-fp-60">(Optional)</span></p>
          </template>
        </AppInput>

        <div class="mb-18">
          <div class="flex gap-x-10 mb-2">
            <AppInput
              v-model="v$.day.$model"
              name="day"
              label="Day"
              :error="errorMessages.month || errorMessages.day || errorMessages.year"
              class="w-4/12"
              @focus="clearErrorField(['day','month','year'])"
            />
            <AppSelect
              v-model="v$.month.$model"
              label="Month"
              option-key="name"
              key-value="name"
              hide-error-message
              :error="errorMessages.month || errorMessages.day || errorMessages.year"
              :options="listOfMonth"
              @focus="clearErrorField(['day','month','year'])"
            />
            <AppInput
              v-model="v$.year.$model"
              name="year"
              label="Year"
              hide-error-message
              :error="errorMessages.month || errorMessages.day || errorMessages.year"
              class="w-4/12"
              @focus="clearErrorField(['day','month','year'])"
            />
          </div>
        </div>

        <AppSelect
          v-model="v$.school.$model"
          label="School"
          class="mb-18"
          option-key="name"
          key-value="name"
          :options="schools"
          :error="errorMessages.school"
          @focus="clearErrorField('school')"
          @change="onSchoolChanged"
        />

        <AppInput
          v-if="showOtherSchoolInput"
          v-model="v$.otherSchoolName.$model"
          label="School name"
          name="otherSchoolName"
          class="mb-18"
          maxlength="100"
          :error="errorMessages.otherSchoolName"
          @focus="clearErrorField('otherSchoolName')"
          @blur="validateField('otherSchoolName')"
        />

        <AppSelect
          v-model="v$.grade.$model"
          label="Grade"
          option-key="value"
          key-value="value"
          :disabled="!availableGradeList.length"
          :options="availableGradeList"
          :error="errorMessages.grade"
          @focus="clearErrorField('grade')"
        />
      </form>
    </div>

    <!-- A C T I O N S  M O B I L E -->
    <div
      ref="stickyActions"
      class="mobile-actions"
    >
      <AppButton
        type="primary"
        custom-class="w-full mr-5"
        @click="onSubmit"
      >
        {{ stage === 'create' ? 'Add Child' : 'Update Child' }}
      </AppButton>

      <AppButton
        type="secondary"
        custom-class="w-full ml-5"
        @click="router.back()"
      >
        Cancel
      </AppButton>
    </div>
  </div>

  <!-- A C T I O N S  D E S K T O P -->
  <div class="hidden sm:flex items-center justify-around md:justify-center">
    <AppButton
      type="primary"
      custom-class="w-full md:w-auto md:px-60 mr-5"
      @click="onSubmit"
    >
      {{ stage === 'create' ? 'Add Child' : 'Update Child' }}
    </AppButton>

    <AppButton
      v-if="stage === 'edit'"
      type="error"
      plain
      custom-class="w-full md:w-auto md:px-60 mr-5"
      @click="$emit('remove', data);"
    >
      Remove Child
    </AppButton>

    <AppButton
      type="secondary"
      custom-class="w-full md:w-auto md:px-40 ml-5"
      @click="router.back()"
    >
      Cancel
    </AppButton>
  </div>
</template>

<script lang="ts">
  import { defineComponent, reactive, computed, PropType, toRefs, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useVuelidate } from "@vuelidate/core";
  import { required, helpers } from "@vuelidate/validators";
  import dayjs from 'dayjs';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppButton from '@/components/stateless/AppButton.vue';

  import { IErrorFields, IChildForm, ISchool, IChild } from '@/types';
  import { clearErrorField, handleSetErrors, validateField, validDate } from '@/core/helper-functions';
  import { addChildFormRules } from '@/views/children/children-validation-rules';

  export default defineComponent({
    name: 'ChildForm',

    components: { AppInput, AppSelect, AppButton },

    props: {
      data: {
        type: Object as PropType<IChildForm>,
        required: true
      },

      stage: {
        type: String,
        validator: (value: string) => ['create', 'edit'].indexOf(value) !== -1,
        default: 'create'
      },

      schools: {
        type: Array as PropType<ISchool[]>,
        default: () => []
      }
    },

    emits: ['submit', 'remove'],

    setup(props, { emit }) {
      const router = useRouter();
      const { data } = toRefs(props);

      const errorMessages = reactive<IErrorFields>({
        firstName: '',
        lastName: '',
        studentId: '',
        nickname: '',
        school: '',
        grade: '',
        otherSchoolName: '',
        day: '',
        month: '',
        year: ''
      });

      const listOfMonth = ref([
        { name: 'January', value: '01' },
        { name: 'February', value: '02' },
        { name: 'March', value: '03' },
        { name: 'April', value: '04' },
        { name: 'May', value: '05' },
        { name: 'June', value: '06' },
        { name: 'July', value: '07' },
        { name: 'August', value: '08' },
        { name: 'September', value: '09' },
        { name: 'October', value: '10' },
        { name: 'November', value: '11' },
        { name: 'December', value: '12' },
      ]);

      const showOtherSchoolInput = computed<boolean>(() => data.value.school?.name === 'Other');

      // validate otherSchoolName if user select "Other" in school section
      const v$ = useVuelidate({
        ...addChildFormRules,
        otherSchoolName: computed<any>(() => showOtherSchoolInput.value && { required: helpers.withMessage('This field cannot be empty', required) }),
        day: computed<any>(() => (
          {
            ...addChildFormRules.day,
            invalideDate: helpers.withMessage(
              'Please enter real date', () => {
                const date = data.value.month?.value + '/' + data.value.day + '/' + data.value.year;
                return validDate(date, 'MM/D/YYYY') && !dayjs(date).isAfter(dayjs());
              }
            )
          })
        )
      }, data.value);

      const availableGradeList = computed(() => {
        if (showOtherSchoolInput.value) {
          return [
            { value: 'pre-K' },
            { value: 'K' },
            { value: 'Grade 1' },
            { value: 'Grade 2' },
            { value: 'Grade 3' },
            { value: 'Grade 4' },
            { value: 'Grade 5' },
            { value: 'Grade 6' },
            { value: 'Grade 7' },
            { value: 'Grade 8' },
            { value: 'Grade 9' },
            { value: 'Grade 10' },
            { value: 'Grade 11' },
            { value: 'Grade 12' },
          ];
        }
        if (data.value.school.grades) {
          return data.value.school.grades.map((el: string) => {
            return { value: el };
          });
        }
        return [];
      });

      const schoolForSubmit = computed(() => {
        return showOtherSchoolInput.value
          ? { id: null, name: data.value.otherSchoolName }
          : { id: data.value.school.id, name: null };
      });

      const dataForSubmit = computed<Partial<IChild>>(() => {
        const {
          firstName,
          lastName,
          nickname,
          studentId,
          grade,
          day,
          month,
          year
        } = data.value;

        return {
          firstName,
          lastName,
          birthMonthDate: dayjs(year + '-' + (month as any).value + '-' + day).toISOString(),
          nickname: nickname ? nickname : null,
          studentId: studentId ? studentId : null,
          otherSchoolName: schoolForSubmit.value.name,
          grade: grade.value,
          schoolId: schoolForSubmit.value.id
        };
      });

      async function onSubmit() {

        if (await v$.value.$validate()) { emit('submit', dataForSubmit.value); }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      function onSchoolChanged() {
        data.value.grade = {};
      }

      return {
        errorMessages,
        availableGradeList,
        router,
        listOfMonth,
        showOtherSchoolInput,
        v$,

        onSubmit,
        onSchoolChanged,
        validateField: (name: string) => validateField(name, v$, errorMessages),
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
</script>

<style lang="scss" scoped>
  .mobile-actions {
    @apply fixed -bottom-1 right-20 left-20 
          flex sm:hidden items-center justify-center 
          bg-white z-5 py-14 px-14 rounded-t-5;

    box-shadow: 0 -4px 15px 0 rgba(0, 0, 0, 0.05);
  }
</style>