import { required, helpers } from "@vuelidate/validators";

const addChildFormRules = {
  birthMonthDate: {},
  day: {
    required: helpers.withMessage('Invalid Date', required)
  }, 
  month: {
    required: helpers.withMessage('Invalid Date', required)
  }, 
  year: {
    required: helpers.withMessage('Invalid Date', required)
  },
  firstName: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  lastName: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  school: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  grade: {
    required: helpers.withMessage('This field cannot be empty', required)
  },
  studentId: {},
  nickname: {}
};

export {
  addChildFormRules
}; 